<template>
  <div
    class="flex flex-col border-r pr-4 border-solid border-gray-200 m-container"
  >
    <div class="flex justify-between align-baseline" id="header">
      <p class="text-base-content-300 mb-0 text-sm grow">{{ title }}</p>
      <!-- <EditPencil @click="handleEditClick" class="cursor-pointer"/> -->
    </div>
    <p
      v-if="this.$route.meta.showLightRay"
      class="font-semibold text-sm capitalize"
      :class="!value && 'text-gray-400'"
    >
      {{ lightRayCaseId || getCaseFromSessionStorage }}
    </p>
    <div v-else class="flex w-full items-center justify-between">
      <div
        class="font-semibold text-sm capitalize"
        :class="!value && 'text-gray-400'"
      >
        {{ value }}
      </div>
      <div
        v-if="edit"
        class="items-center pt-1 cursor-pointer"
        @click="handleEditCaseName"
      >
        <EditPencil class="h-5 w-5" />
      </div>
    </div>
    <ModalContent
      name="editCaseName"
      :ref="modalRefName"
      title="Case Name"
      :max-width="350"
      @close="closeModal"
      clickToClose
      :disabled="!caseName"
      @submit="submitCaseName"
      v-if="edit"
    >
      <template #content>
        <div class="flex flex-col flex-wrap max-w-xs gap-4">
          <FormulateInput
            class="w-full pb-2"
            label="Case Name"
            placeholder="Case Name"
            type="text"
            validation="required"
            v-model="caseName"
          />
        </div>
      </template>
    </ModalContent>
  </div>
</template>

<script>
const EditPencil = () => import("@shared/assets/svg/editIconBlue.svg");
import ModalContent from "@shared/modal-content";
import axios from "@/axios";
import {  mapActions } from "vuex";

export default {
  components: {
    EditPencil,
    ModalContent,
    // EditIcon
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      caseName: "",
      modalRefName: "caseNameEdit"
    };
  },
  computed: {
    lightRayCaseId() {
      return this.$store.getters.getLightRayCaseId;
    },
    getCaseFromSessionStorage() {
      return sessionStorage.getItem("neo_case_id");
    },
  },
  methods: {
    ...mapActions(["fetchCaseData"]),
    handleEditCaseName() {
      this.caseName = this.value
      this.$refs[this.modalRefName].showModal();
    },
    closeModal() {
      this.$refs[this.modalRefName].hideModal();
    },
    async submitCaseName() {
      try {
        if (!this.$route.query.caseid) {
          return;
        }
        const url = `/case/${this.$route.query.caseid}`;
        const response = await axios.put(url, {
          case_name: this.caseName,
        });
        if (response) {
          this.$toast.success(
            response?.data?.message || "Updated successfully."
          );
        }
        await this.fetchCaseData(this.$route.query.caseid);
        console.log("submit");
        this.closeModal();
      } catch (err) {
        console.log("err", err);
        this.$toast.error(
          err?.response?.data?.detail || "Something went wrong."
        );
        this.closeModal();
      }
    },
  },

};
</script>

<style>
.m-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  min-width: 150px;
}
#header {
  min-width: inherit;
}
</style>
