import { delviumLogo } from "@/plugins/common"
// import { defaultLogo } from "@/plugins/common"

import { mapGetters } from "vuex";

import PaintIcon from '@/assets/icons/svg/paint.svg';
import Domain from '@/assets/icons/svg/domain.svg';
import report from '@/assets/icons/svg/clipboard-list.svg';
import WorkflowSvg from '../../assets/icons/workflow/workflowMainMenu.svg'
import WorkflowCase from '../../assets/icons/workflow/workflowCase.svg'
import WorkflowCheck from '../../assets/icons/workflow/workflowCheck.svg'
import PersonIcon from "@shared/assets/icons/entity/person.svg";
import CompanyIcon from "@shared/assets/icons/entity/company.svg";
import taxIcon from "@/assets/icons/svg/manage-tax-main.svg"
import Invoices from '@/assets/icons/svg/invoices.svg';
import Orders from '@/assets/icons/svg/orders.svg';
import Sales from '@/assets/icons/svg/sales.svg';
import creditPackIcon from '@/assets/icons/svg/credit-pack.svg';
import DashboardIcon from '@/assets/icons/svg/dashboard.svg';
import CreateCaseIcon from '@/assets/icons/svg/create-case.svg';
import License from '@/assets/icons/svg/license.svg';
import {getSaasUrl} from "@shared/light-ray/services";
import { cloneDeep } from "lodash";
import DefaultEmail from "@/assets/icons/email-admin/icon-defaultemail.svg";
import EmailSequence from "@/assets/icons/email-admin/icon-templatesequence.svg";
import salesMain from "@/assets/icons/svg/sales-main.svg"
import salesOrders from "@/assets/icons/svg/sales-orders.svg"
import { checkPermission } from "@shared/utils/functions"

export default {
  name: "app-sidebar",
  components: {
    DefaultEmail,
    EmailSequence
  },
  props: [],
  data() {
    return {
      navigation: [],
      case:{
          link: "/create-case",
          name: "Create case",
          svg: CreateCaseIcon,
      },
      dashboard: {
          link: "/",
          name: "Dashboard",
          svg: DashboardIcon,
          pathAliase: '/case-dashboard',
      },
      //  Admin menu
      admin:{
        link: "/admin",
        name: "Admin",
        icon: "user-shield",
        children: [
        ],
      },
      field:{
          link: "/field-admin",
          name: "Fields",
          icon: "table",
          breadcrumbBranch: ['Admin', 'Field List'],
      },
      check:{
          link: "/check-admin",
          name: "Checks",
          icon: "check",
          breadcrumbBranch: ['Admin', 'Check List'],
      },
      package:{
          link: "/packages",
          name: "Packages",
          icon: "database",
          breadcrumbBranch: ['Admin', 'Package List'],
      },
      riskAssessment:{
          link: "/risk-assessment",
          name: "Risk Assessment",
          icon: "user-shield",
          breadcrumbBranch: ['Admin', 'Risk Assessment'],
      },
      clientList:{
          link: "/clients-list",
          name: "Clients",
          icon: "user-secret",
          breadcrumbBranch: ['Admin', 'Client List'],
      },
      user:{
        link: "/users",
        name: "Users",
        icon: "user",
        breadcrumbBranch: ['Admin', 'Users'],
      },
      group:{
        link: "/groups",
        name: "Groups",
        icon: "users-cog",
        breadcrumbBranch: ['Admin', 'Groups'],
      },
      email:{
        link: "/emails",
        name: "emails",
        icon: "envelope-open",
        children: [
          {
            link: "/email-admin",
            name: "Templates & Sequences",
            customEmail: true,
            svg: EmailSequence,
          },
          {
            link: "/default-template",
            name: "Default Template & Sequences",
            customEmail: true,
            svg: DefaultEmail,
          },
        ],
      },
      form:{
        link: "/form-admin",
        name: "Forms",
        icon: "keyboard",
        breadcrumbBranch: ['Admin', 'Forms'],
      },
      rolePermission:{
        link: "/roles-and-permissions",
        name: "Roles & Permissions",
        icon: "user",
        breadcrumbBranch: ['Admin', 'Roles & Permissions'],
      },
      creditPack:{
        link: "/credit-packs",
        name: "Credit Packs",
        svg: creditPackIcon,
        breadcrumbBranch: ['Admin', 'Credit Packs'],
      },
      license:{
        link: "/license",
        name: "License",
        svg: License,
        breadcrumbBranch: ['Admin', 'License'],
      },
      tax: {
        link: "/manage-tax",
        name: "Manage Tax",
        svg: taxIcon,
        breadcrumbBranch: ['Admin', 'Manage Tax'],
      },
      // Purcahse 
      purchase: {
        link: "/purchase",
        name: "Purchase",
        svg: Sales,
        children: [
        ],
      },
      purchaseOrder:{
        link: "/purchase-order",
        name: "Orders",
        svg: Orders,
        breadcrumbBranch: ['Purcahse', 'Orders'],
      },
      purchaseInvoice:
      {
        link: "/invoice",
        name: "Invoices",
        svg: Invoices,
        breadcrumbBranch: ['Purcahse','Invoices'],
      },
      sales:{
        link: "/sales",
        name: "Sales",
        svg: salesMain,
        children: [
        ]
      },
      salesOrders:{
        link: "/sales-orders",
        name: "Orders",
        svg: salesOrders
      },
      salesInvoice:{
        link: "/sales-invoice",
        name: "Invoices",
        svg: Invoices,
        breadcrumbBranch: ['Sales','Invoices'],
      },
      setting:{
        link: "/settings",
        name: "Settings",
        icon: "cog",
        children: [
        ],
      },
      logo:{
        link: "/logo-and-theme",
        name: "Logo & Theme",
        svg: PaintIcon,
        breadcrumbBranch: ['Settings', 'Logo & Theme'],
      },
      domain: {
        link: "/custom-domain",
        name: "Custom Domain",
        svg: Domain,
        breadcrumbBranch: ['Settings','Custom Domain'],
      },
      workflowEngine: {
        link: "/workflow",
        name: "Workflow Engine",
        svg: WorkflowSvg,
        children: [
          {
            link: "/workflow-engine/person",
            name: "Person",
            svg: PersonIcon,
            breadcrumbBranch: ['Admin', 'Workflow Engine', 'Person'],
          },
          {
            link: "/workflow-engine/company",
            name: "Company",
            svg: CompanyIcon,
            breadcrumbBranch: ['Admin', 'Workflow Engine','Company'],
          },
          {
            link: "/workflow-engine/case",
            name: "Case",
            svg: WorkflowCase,
            breadcrumbBranch: ['Admin', 'Workflow Engine', 'Case'],
          },
          {
            link: "/workflow-engine/check",
            name: "Checks",
            svg: WorkflowCheck,
            breadcrumbBranch: ['Admin', 'Workflow Engine', 'Checks'],
          },
        ],
      },
      reportTemplates:{
        link: "/report-templates",
        name: "Report Templates",
        svg: report,
        breadcrumbBranch: ['Admin', 'Report Templates'],
      },
      delviumLogo,
      // defaultLogo,
      isPoweredByVisible: false,
    };
  },
  async mounted(){
    await this.navigationList();
    const themeObj = localStorage.getItem('themeObj');
    this.isPoweredByVisible = themeObj && JSON.parse(themeObj)?.powered_by;
  },
  watch: {
    isPermissionUpdate() {
      this.navigationList();
    }
  },
  computed: {
    ...mapGetters(['getThemeLogo', 'getIsPermission']),
    isNavCollapsed() {
      return this.$store.getters.getNavCollapsed || false;
    },
    isEmailNavCollapsed() {
      return this.$store.getters.getEmailNavCollapsed || false;
    },
    isPermissionUpdate() {
      return this.$store.getters.getIsPermission
    }
  },
  methods: {
    checkPermission,
    navigationList(){
      if(checkPermission('case.read') == true){
        this.navigation.push(this.case)
      }
      if(checkPermission('dashboard.read') == true){
        this.navigation.push(this.dashboard)
      }
      if (checkPermission('field.read') == true || checkPermission('block.read') == true || 
          checkPermission('check.read') == true || checkPermission('package.read') == true ||  
          checkPermission('form.read') == true || checkPermission('client.read') == true || 
          checkPermission('groups.read') == true || checkPermission('users.read') == true ||
          checkPermission('creditPack.read') == true || checkPermission('tax.read') == true ||
          checkPermission('license.read') == true ||  checkPermission('email.read') == true ||
          checkPermission('role.read') == true || checkPermission('workflow.read') == true ||
          checkPermission('riskAssessment.read') == true || checkPermission('reportTemplates.read') == true
        ) {
        this.navigation.push(this.admin)
        this.navigation.forEach(elv => {
          if(elv.name == "Admin"){
            if(checkPermission('field.read') == true || checkPermission('block.read')){
              elv.children.push(this.field)
            }
            if(checkPermission('check.read') == true){
              elv.children.push(this.check)
            }
            if(checkPermission('package.read') == true){
              elv.children.push(this.package)
            }
            if(checkPermission('form.read') == true){
              elv.children.push(this.form)
            }
            if(checkPermission('client.read') == true){
              elv.children.push(this.clientList)
            }
            if(checkPermission('groups.read') == true){
              elv.children.push(this.group)
            }
            if(checkPermission('users.read') == true){
              elv.children.push(this.user)
            }
            if(checkPermission('creditPack.read') == true){
              elv.children.push(this.creditPack)
            }
            if(checkPermission('tax.read') == true){
              elv.children.push(this.tax)
            }
            if(checkPermission('license.read') == true){
              elv.children.push(this.license)
            }
            if(checkPermission('email.read') == true){
              elv.children.push(this.email)
            }
            if(checkPermission('role.read') == true){
              elv.children.push(this.rolePermission)
            }
            if(checkPermission('workflow.read') == true){
              elv.children.push(this.workflowEngine);
            }
            if(checkPermission('riskAssessment.read') == true){
              elv.children.push(this.riskAssessment)
            }
            if(checkPermission('reportTemplates.read') == true){
              elv.children.push(this.reportTemplates);
            }
            // if(checkPermission('check.read') == true){
            //   elv.children.push(this.check)
            // }
          }
        })
      }
      if(checkPermission('purchaseOrder.read') == true || checkPermission('invoice.read') == true )
        {
          this.navigation.push(this.purchase)
          this.navigation.forEach(elv => {
            if(elv.name == "Purchase"){
              if(checkPermission('purchaseOrder.read') == true){
                elv.children.push(this.purchaseOrder)
              }
              // if(checkPermission('invoice.read') == true){
              //   elv.children.push(this.purchaseInvoice)
              // }
            }
          })
        }
        if(checkPermission('salesOrder.read') == true || checkPermission('salesInvoice.read') == true )
        {
          this.navigation.push(this.sales)
          this.navigation.forEach(elv => {
            if(elv.name == "Sales"){
              if(checkPermission('salesOrder.read') == true){
                elv.children.push(this.salesOrders)
              }
              // if(checkPermission('salesInvoice.read') == true){
              //   elv.children.push(this.salesInvoice)
              // }
            }
          })
        }
        if(checkPermission('theme.edit') == true || checkPermission('domain.read') == true )
        {
          this.navigation.push(this.setting)
          this.navigation.forEach(elv => {
            if(elv.name == "Settings"){
              if(checkPermission('theme.edit') == true){
                elv.children.push(this.logo)
              }
              if(checkPermission('domain.read') == true){
                elv.children.push(this.domain)
              }
            }
          })
        }
        // if (this.navigation?.length > 0 && !this.navigation.some(item => item.name == this.tool.name)) {
        //   this.navigation.push(this.tool)
        // }
      },
    isActive(item) {
      const breadcrumb = this.$route?.meta?.breadcrumb;
      return this.$route.path === item.link
        || this.$route.matched.some(el => el.path == item.link || el.path === item.pathAliase)
        // check breadcrumbs to determine child routes (in our case, routes of the same branch):
        || (!!breadcrumb && breadcrumb[0].name === item.breadcrumbBranch?.[0] && breadcrumb[1].name === item.breadcrumbBranch?.[1] && breadcrumb[2].name === item.breadcrumbBranch?.[2])
    },
    navCollapseToggle() {
      const navCollapsed = this.$store.getters.getNavCollapsed || false
      this.$store.dispatch('setNavCollapsed', !navCollapsed)
    },
    async handleNavClick(nav) {
      let dropdownElement = document.getElementById('dashboard_container_embeddedbi_ConfigMenu');
      if (dropdownElement) dropdownElement.style.display = 'none'
      if (!this.isActive(nav.link)) {
        if (nav.children && nav.children.length  > 0 && nav.name.toLowerCase() !== 'emails' &&  nav.name.toLowerCase() !== 'workflow engine') {
          let expanded = nav.expanded || false;
          this.navigation = this.navigation.map((nve) =>
            nve.link === nav.link ? { ...nav, expanded: !expanded, expandChildren: false } : nve
          );
          this.navigation.forEach(el => {
            if(el.children && el.name.toLowerCase() === 'admin') {
              el.children.forEach((el1) => {
                  el1.expandMenu = false;
              })
            }
          })
        }
        else if (nav.children && nav.children.length  > 0 && (nav.name.toLowerCase() === 'emails' || nav.name.toLowerCase() === 'workflow engine')) {
          console.log("2");
          let expanded = nav.expandMenu || false;
          // const expand = this.navigation;
          this.navigation.forEach(el => {
            if(el.children && el.name.toLowerCase() === 'admin') {
              el.children.forEach((el1) => {
                if(el1.link === nav.link) {
                  el1.expandMenu = !expanded
                }
              })
            }
          })
          cloneDeep(this.navigation)
          let isExpandChildren = false;
          this.navigation.forEach((el) => {
            if(el.link === '/admin' && el.children) {
                el.children.forEach((child) => {
                  if(child?.expandMenu) {
                    isExpandChildren = true;
                  }
              });
            }
          })
          this.navigation = this.navigation.map((nve) =>
          nve.link === '/admin' ? { ...nve, expandChildren: isExpandChildren } : nve);

        }
         else {
          console.log("3");
          if (nav.customLink) {
            const payload = {
              company_id: this.$store.getters.getUser.id,
              user_id: this.$store.getters.getTenantId
            }
            const {data} = await getSaasUrl(payload)
            window.open(data.research_url, "_blank")
          } else  {
            this.$router
              .push({ path: nav.link })
              .catch((e) => console.log("e >> ", e));
          }
        }
      }
    },
    getNavElement(nav) {
      let dropdownElement = document.getElementById('dashboard_container_embeddedbi_ConfigMenu');
      if (dropdownElement) dropdownElement.style.display = 'none'
      const link = {
        bind: {
          is: 'router-link',
          tag: 'a',
          to: nav.link,
        },
      };
      const submenu = {
        bind: {
          is: 'div',
        },
        on: {
          'click': () => this.handleNavClick(nav),
        }
      };
      
      if (nav.customLink) {
        return submenu
      } else {
        return nav.children && nav.children.length > 0 ? submenu : link;
      }
    },
  },
};
